<template>
    <b-card
        no-body
        class="card-payment"
    >
        <b-card-header>
            <b-card-title>Filtro</b-card-title>
        </b-card-header>
        <b-card-body>
            <b-form>
                <b-row>
                    <b-col lg="3" md="6" sm="6">
                        <b-form-group
                            label="TIPO DE CLIENTE"
                            label-for="type"
                            class="mb-2"
                        >
                            <b-form-input
                                id="code"
                                v-model="search.type"
                                type="text"
                                autocomplete="off"
                                @keyup="searchClientTypes()"
                            />
                        </b-form-group>
                    </b-col>

                </b-row>
            </b-form>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm } from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import {boolean} from "@/constants/constants"


const {mapFields} = createHelpers({
    getterType: 'auxiliariesClientTypesVhe/getField',
    mutationType: 'auxiliariesClientTypesVhe/updateField',
})

export default {
    name: "SearchView",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        clickSearch: {
            type: Function,
        }
    },
    data() {
        return {
            clients: [],
            states: [],

            boolean
        }
    },
    computed: {
        ...mapFields({
            search: 'search',
        }),
    },
    methods: {
        searchClientTypes: function (){
            this.clickSearch()
        },
    }
}
</script>

<style scoped>

</style>